<template>
    <section>
        <div class="row mt-5 leaf-background-3" data-aos="fade-up" data-aos-duration="3000">
            <div class="offset-md-2 col-md-8 col-sm-12">
                <div class="card avis m-4">
                    <div class="card-body">
                        <template v-if="image">
                            <picture>
                                <source :srcset="image.webpImage" type="image/webp">
                                <img
                                class="d-block img-xl rounded-circle mx-auto mb-2"
                                :src="image.originalImage"
                                alt="profile image"
                                />
                            </picture>
                        </template>
                        <img v-else class="d-block img-xl rounded-circle mx-auto mb-2" src="@/assets/images/import/fille.png" alt="profile image">
                        <span v-html="text"></span>
                    </div>
                </div>
            </div>
            <div class="col-md-3"></div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
export default {
    name:'biographie',
    data(){
        return {
            text:'',
            image:''
        }
    },
    methods:{
        getsection(){
            axios.get(`sections/01FZ0QZ7RV63DJPQDGGSKAWR0N`)
            .then(res => {
                this.text = res.data.blocks[0].data[0].data.text
                this.image = {
                    originalImage:res.data.blocks[0].data[0].data['original-image-url'],
                    webpImage:res.data.blocks[0].data[0].data['webp-image-url']
                    }
                })
            .catch(err => err)
        }
    },
    created(){
        this.getsection()
    }
}
</script>
<style scoped>
.leaf-background-3 {
    position: relative;
    min-height: 30rem;
    background-image: url('../../assets/images/import/fond-branche-3.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
</style>
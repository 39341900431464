<template>
    <section>
        <!-- carousel -->
        <div class="row">
            <div class="col-md-12">
                <carouselSlick class=""/>
                <div class="gold-line">
                </div>
                <!-- <carousel-meb class="white-border-bottom-gold" /> -->
            </div>
        </div>
        <!-- Services -->
        <div class="content-wrapper" data-aos="fade-up" data-aos-duration="3000">
            <div class="row border-bottom mt-4 mb-5">
                <div class="col-md-12">
                    <h2>Prestations</h2>
                </div>
                <div class="col-md-12">
                    <animated-circle-card-slick
                    scrollcards
                    entitytype="categories"
                    title="category"
                    description="description"
                    />
                </div>
                <div class="col-md-12 d-flex justify-content-center mb-4">
                    <router-link to="/services">
                        <button class="btn btn-gradient-primary">Détails des prestations</button>
                    </router-link>
                </div>
            </div>
        </div>
        <!-- Origine certifiée -->
        <div class="leaf-background" data-aos="fade-up" data-aos-duration="3000">
            <div class="content-wrapper no-background">
                <div class="row">
                    <div class="col-md-12 d-flex justify-content-center">
                        <h2 data-aos="fade-up" data-aos-duration="3000">Marques de l'institut</h2>
                    </div>
                    <institute />
                </div>
            </div>
        </div>
        <div class="content-wrapper no-background" data-aos="fade-up" data-aos-duration="3000">
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6 col-sm-12 mt-2">
                            <h2>Nos produits</h2>
                        </div>
                        <div class="col-md-6 col-sm-12 mt-2 d-flex justify-content-md-end">
                            <router-link class="btn btn-outline-primary btn-minimalist mt-4" to="/boutique">Tous nos produits >></router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="green-border-bottom-gold" style="min-height:590px">
            <div class="content-wrapper no-background">
                <!-- Nos Produits -->
                <div class="row" data-aos="fade-left" data-aos-duration="3000">
                    <div class="col-md-12">
                        <div v-for="block in products.blocks" :key="block.id">
                            <gold-card
                            scrollcards
                            title="name"
                            description="description"
                            price="price"
                            entitytype="products"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="leaf-background-2" id="info"  data-aos="fade-up" data-aos-duration="3000">
            <div class="content-wrapper no-background">
                <!-- infos pratiques -->
                <div class="row mt-5 ">
                    <div class="col-md-12 ">
                        <!-- <img style="position:relative; top:-26%; width: 100%" src="@/assets/images/import/fond-branche-2.png" alt=""> -->
                        <div class="mt-3">
                            <h2 class="text-center underline">Infos pratiques</h2>
                            <div class="row">
                                <div class="offset-md-2 col-md-4 col-sm-12 mt-5">
                                    <h3>Accueil Magasin</h3>
                                    <p class="border-left pl-2">
                                        Mardi, Jeudi, Vendredi de 9h30 à 18h <br> Mercredi de 9h30 à 19h00 <br> et le Samedi de 10h30 à 16h
                                    <br>
                                    </p>
                                </div>
                                <div class="col-md-4 col-sm-12 mt-5">
                                    <h3 class="text-right">Accueil Institut</h3>
                                    <div class="d-flex justify-content-end">
                                    <p class="text-right border-right pr-2">
                                         Mardi, Jeudi, Vendredi de 9h30 à 18h <br> Mercredi de 9h30 à 19h00 <br> et le Samedi de 10h30 à 16h
                                    </p>
                                    <!-- <img data-aos="fade-left" data-aos-duration="3000" class="" style="width:8rem" src="@/assets/images/import/Feuilles_or.png" alt="" align="right"> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Coordonnées et carte -->
                    <div class="col-md-12 d-flex justify-content-center mt-5">
                        <div class="row mt-5">
                            <div class="col-md-6 text-center col-sm-12 mb-1 d-flex justify-content-center">
                                <div class="card border" data-aos="fade-right" data-aos-duration="3000">
                                    <div id="contact" class="card-body px-é d-flex align-content-center flex-wrap">
                                        <h3 style="font-weight:bolder"><u>PRISE DE RENDEZ-VOUS</u></h3>
                                        <div class="d-flex">
                                            <img src="@/assets/images/import/icon-phone.png" alt="">
                                            <p class="mx-3">06 75 43 75 22</p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <img src="@/assets/images/import/icon-computer.png" alt="">
                                            <!-- <p class="mx-3 pointer" @click="reservation()"><b>Réserver en ligne</b></p> -->
                                            <button class="btn btn-light mx-3" @click="reservation()">Réserver en ligne</button>
                                        </div>

                                        <h3 style="font-weight:bolder" class="mt-3 text-left"><u>ACHAT DE PRODUITS,<br> CARTES CADEAUX</u> </h3>
                                        <div class="d-flex">
                                            <img src="@/assets/images/import/icon-house.png" alt="">
                                            <p class="mx-3 text-left">64 Av. Saint-Exupery, 81 600 Gaillac</p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <img src="@/assets/images/import/icon-computer.png" alt="">
                                            <!-- <p class="mx-3 pointer" @click="shop()">Boutique en ligne</p> -->
                                            <button class="btn btn-light mx-3" @click="shop()">Boutique en ligne</button>
                                        </div>
                                        <!-- Coordonnées et adresse -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12 text-center mb-1 d-flex justify-content-center">
                                <div class="card border" data-aos="fade-left" data-aos-duration="3000">
                                    <div id="map" class="card-body">
                                        <!-- <img src="@/assets/images/import/test.png" alt=""> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- description + dessin -->
                    <biographie/>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Biographie from '../components/dynamic/biographie.vue'
import animatedCircleCardSlick from '../components/element/animated-circle-card-slick.vue'
// import animatedCircleCard from '../components/element/animated-circle-card.vue'
// import carouselMeb from '../components/element/carousel-meb.vue'
import carouselSlick from '../components/element/carousel-slick.vue'
// import circleCard from '../components/element/circle-card.vue'
import goldCard from '../components/element/gold-card.vue'
import institute from '../components/page/institute.vue'
import products from '../json/products.json'
export default {
    name: 'home-2',
    components: {
        goldCard,
        // carouselMeb,
        carouselSlick,
        // animatedCircleCard,
        animatedCircleCardSlick,
        institute,
        Biographie
    },
    data () {
        return {
            products:products
        }
    },
    methods: {
        reservation() {
            this.$router.push ({
                name: "reservation"
            })
        },
        shop() {
            this.$router.push ({
                name: "shop"
            })
        }
    }
}
</script>
<style scoped>
.leaf-background {
    position: relative;
    min-height: 30rem;
    background-image: url('../assets/images/import/branche-3.png'), url('../assets/images/import/branche-4.png');
    background-attachment: fixed;
    background-size: 38%, 50%;
    background-position: center left -17rem, center right -19rem;
    background-repeat: no-repeat;
}
.leaf-background-2 {
    position: relative;
    min-height: 30rem;
    background-image: url('../assets/images/import/branche-1.png'), url('../assets/images/import/branche-2.png');
    background-size: 30%, 50%;
    background-position:top -18rem left 9rem, top -20rem right -9rem;
    background-repeat: no-repeat;
}



/* carte vide */
.border {
    border: #B7C4B3 3px solid !important;
    width: 25rem;
    height: 25rem;
}
@media screen and (max-width: 939px) {
    .border {
        width: 21rem;
        height: 21rem
    }
    #contact h3{
    font-size: 18px !important
    }
    #contact p {
        font-size: 16px !important
    }
}

.avis {
    background: #F9FBF9;
    border: 1px grey solid;
}
.test-color {
    background: red;
}

#contact img {
    width: 2rem;
    height: 1.7rem;
}

#contact h3{
    font-size: 24px
}
#contact p {
    font-size: 20px
    /* #1f2326, #696d70, #161819 */
}


#map {
    background-image: url('../assets/images/import/map-2.png');
    background-size: cover;
    width: auto;
}

.before-enter{
    opacity: 0;
    transform: translateY(100px);
    transition: all 2s ease-out;
}

.enter{
    opacity: 1;
    transform: translateY(0px);
}


</style>